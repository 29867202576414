import { runWithAdal } from 'react-adal';
import { authContext } from './services/adalConfig';

const DO_NOT_LOGIN = false;

//console.log(authContext);
runWithAdal(authContext, () => {
  //eslint-disable-next-line
  require('./indexApp.js');

},DO_NOT_LOGIN);