import React from "react";

const TranslationsSwitcher = ({languages, selectedLanguage, onChangeLanguage}) => {

    const options = languages.map((item) => {
        return <option key={item}>{item}</option>
    });

    return (
        <div className="col-12 mb-3">
            <select 
                className="col-4 form-select translations-switcher"
                aria-label="Default select example"
                value={selectedLanguage}
                onChange={(event) => {onChangeLanguage(event.target.value)}}
            >
                {options}
            </select>
        </div>
    );
};

export default TranslationsSwitcher;