import { adalConfig, authContext } from './adalConfig';
import { toast } from 'react-toastify';

const getAuthorizationToken = () => {
  return new Promise((resolve, reject) => {
    authContext.acquireToken(
      adalConfig.clientId,
      (errorMessage, accessToken) => {
        if (errorMessage) {
          reject(errorMessage);
        } else {
          resolve(accessToken);
        }
      }
    );
  });
};
export default class ApiService {
  async getData() {
    try {
      const response = await fetch(
        'https://#{ApiUrl}#/api/translations'
      );
      const data = await response.json();
      return data;
    } catch (error) {
      console.error(error);
    }
  }

  async saveData(data) {
    let token;
    try {
      token = await getAuthorizationToken();
    } catch (err) {
      toast.error('Can not get token');
      console.error(err);
    }

    if (token) {
      try {
        const responce = await fetch(
          'https://#{ApiUrl}#/api/translations',
          {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
          }
        );

        if (responce.status < 200 || responce.status > 399) {
          throw new Error(`Request error with status ${responce.status}.`);
        }

        toast.info('Success!');
      } catch (err) {
        console.error(err);

        if (typeof err == 'string') {
          toast.error(err);
        } else {
          toast.error(err.message || err.errorMessage);
        }
      }
    }
  }
}
