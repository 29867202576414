import React from "react";
import TranslationsListItem from "../transitions-list-item";
import "./translations-list.css"

const TranslationsList = ({language, dictionary, onChangeHandler}) => {
    
    const items = Object.entries(dictionary).map(([label, value]) => {
    const key = `${language}_${label}`;
    return <TranslationsListItem 
      key={key} 
      label={label} 
      value={value} 
      onChangeHandler={(newValue) => onChangeHandler(label, newValue)}
    />
  });

  return (
    <div className="col-12">
        <div className="list-group translations-list">
            {items}
        </div>
    </div>
  );
};

export default TranslationsList;