import React, { Component } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ApiService from '../../services/api.service';
import TranslationsHeader from '../translations-header';
import TranslationsSwitcher from '../translations-switcher';
import TranslationsList from '../translations-list';
import './app.scss';

export default class App extends Component {
  state = {
    loading: true,
    dictionaries: {},
    selectedLanguage: 'en',
    selectedDictionary: {}
  };

  apiService = new ApiService();

  async componentDidMount() {
    const dictionaries = await this.apiService.getData();

    this.setState({
      loading: false,
      dictionaries,
      selectedDictionary: dictionaries[this.state.selectedLanguage]
    });
  }

  getLanguages() {
    return Object.keys(this.state.dictionaries);
  }

  onChangeLanguage(language) {
    this.setState({
      selectedLanguage: language,
      selectedDictionary: this.state.dictionaries[language]
    });
  }

  onChangeItemValue(label, newValue) {
    this.setState(state => ({
      selectedDictionary: {
        ...state.selectedDictionary,
        [label]: newValue
      }
    }));
  }

  saveDictionary() {
    const newState = {
      dictionaries: {
        ...this.state.dictionaries,
        [this.state.selectedLanguage]: {
          ...this.state.selectedDictionary
        }
      }
    };

    this.setState(newState, () => {
      this.apiService.saveData(newState.dictionaries);
    });
  }

  resetToSavedDictionary() {
    this.setState(state => ({
      selectedDictionary: state.dictionaries[state.selectedLanguage]
    }));
  }

  render() {
    const languages = this.getLanguages();
    const selectedLanguage = this.state.selectedLanguage;
    const selectedDictionary = this.state.selectedDictionary;

    return (
      <div className="app container-fluid">
        <ToastContainer hideProgressBar pauseOnHover theme="colored" />
        <form className="translations" noValidate>
          <div className="row">
            <TranslationsHeader
              onSave={() => this.saveDictionary()}
              onReset={() => this.resetToSavedDictionary()}
            />
            <TranslationsSwitcher
              languages={languages}
              selectedLanguage={selectedLanguage}
              onChangeLanguage={value => this.onChangeLanguage(value)}
            />
            {selectedDictionary ? (
              <TranslationsList
                language={selectedLanguage}
                dictionary={selectedDictionary}
                onChangeHandler={(label, newValue) =>
                  this.onChangeItemValue(label, newValue)
                }
              />
            ) : null}
          </div>
        </form>
      </div>
    );
  }
}
