import React, {Component} from "react";
import "./translations-list-item.css";

const TranslationsListItem = ({label, value, onChangeHandler}) => {

    return (
        <div className="d-sm-flex list-group-item translations-list-item">
            <label className="translations-list-label">{label}</label>
            <input 
                type="text" 
                value={value ?? ''}
                onInput={(event) => onChangeHandler(event.target.value)}
            />
        </div>
    );
}

export default TranslationsListItem; 
