import React from "react";
import "./translations-header.css"

const TranslationsHeader = ({onSave, onReset}) => {

    return (
        <div className="d-sm-flex align-items-center mb-3">
            <h1 className="translations-header col-sm-6">Translations</h1>
                <div className="col-sm-6">
                    <div className="row justify-content-sm-end">
                        <div className="col-auto">
                            <button type="button" className="btn btn-sm btn-danger"
                                onClick={() => onReset()}
                            >Cancel</button>
                        </div>
                        <div className="col-auto">
                            <button type="button" className="btn btn-sm btn-primary"
                                onClick={() => onSave()}
                            >Submit</button>
                        </div>
                    </div>
                </div>
        </div>
    )
};

export default TranslationsHeader;
